.faqcontainer {
  display: flex;
  width: 80%;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
  height: 500px;
}

.cardContainerFirst {
  width: 650px;
  height: 370px;
}
.cardContainerSecond {
  margin-left: 20px;
  width: 650px;
  height: 370px;
}

.cardOne {
  margin-top: 25px;
}
.cardTwo {
  margin-top: 25px;
}

.cardThree {
  margin-top: 25px;
}

.cardFour {
  margin-top: 25px;
}
.cardSix {
  margin-top: 25px;
}
.cardSeven {
  margin-top: 25px;
}
.cardEight {
  margin-top: 25px;
}
.cardNine {
  margin-top: 25px;
}

@media screen and (max-width: 480px) {
  .faqcontainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 120px !important;
  }
  .cardContainerFirst {
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-evenly !important;
  }
  .cardContainerSecond {
    flex: 1;
    flex-wrap: wrap;
    margin-left: 0px;
    margin-top: 25px;
    justify-content: space-evenly !important;
  }

  .cardContainerFirst > Card {
    flex-basis: 200px;
  }
  .cardContainerSecond > Card {
    flex-basis: 200px;
  }

  .cardOne {
    margin-top: 20px !important;
  }
  .cardTwo {
    margin-top: 20px !important;
  }

  .cardThree {
    margin-top: 20px !important;
  }
  .cardEight {
    margin-top: -37px !important;
  }
}

@media screen and (max-width: 768px) {
  .faqcontainer {
    width: 70%;
    margin: 0 auto;
    height: 700px;
  }

  .cardContainerFirst {
    position: relative;
    width: 350px;
    height: 500px;
  }

  .cardContainerSecond {
    position: relative;
    width: 350px;
    height: 500px;
  }
  .cardOne {
    margin-top: 50px;
  }
  .cardSeven {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 1024px) {
  .faqcontainer {
    width: 70%;
    margin: 0 auto;
  }
  .cardContainerFirst {
    position: relative;
    width: 350px;
    height: 500px;
  }

  .cardContainerSecond {
    position: relative;
    width: 350px;
    height: 500px;
  }
  .cardTwo {
    margin-top: 45px;
  }
  .cardThree {
    margin-top: 43px;
  }
  .cardSeven {
    margin-top: 22px;
  }
  .cardEight {
    margin-top: 43px;
  }
}
