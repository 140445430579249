.careerContainer {
  margin-top: 90px;
}
.p-large {
  text-align: center;
  font: 300 20px/28px "Open Sans", Arial, Helvetica, sans-serif;
  max-width: 960px;
  margin: 0 auto;
  color: blue;
}
.successgif{
  display: flex;
  justify-content: center;
}
ul.job-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.job-list > li.job-preview {
  background: #fff;
  border: 1px solid #d7e2e9;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  float: left;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

ul.job-list > li.job-preview:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.job-title {
  margin-top: 0.6rem;
  color: black;
}

.company {
  color: #96a4b1;
}

.job-preview .btn {
  margin-top: 1.1rem;
}

.btn-apply {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 1px;
  background-color: transparent;
  color: #393a5f;
  border: 2px solid #393a5f;
  padding: 0.6rem 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
}

.btn-apply:hover {
  background-color: #393a5f;
  color: #fff;
  border: 2px solid #393a5f;
}

@media (max-width: 575px) {
  .job-preview .content {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  #navbar-main {
    background: transparent;
    border-bottom: 1px solid transparent;
  }

  #navbar-main .navbar-brand {
    color: #fff;
    opacity: 0.8;
    padding: 0.95rem 1.2rem;
  }

  #navbar-main .navbar-brand:hover,
  #navbar-main .navbar-brand:focus {
    opacity: 1;
  }

  #navbar-main .navbar-nav > li.nav-item > a {
    color: #fff;
    opacity: 0.8;
    padding: 0.95rem 1.2rem;
  }

  #navbar-main .navbar-nav > li.nav-item > a:hover,
  #navbar-main .navbar-nav > li.nav-item > a:focus {
    opacity: 1;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
:root{
  font-family: 'Varela Round', sans-serif;}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.careerPage {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: white;
  margin: 0;
}

/* mobile friendly alternative to using background-attachment: fixed */
.careerPage::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: var(--color-darkblue);
  background-image: 
    url('../../images/career-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

h1 {
  font-weight: 400;
  line-height: 1.2;
}

p {
  font-size: 1.125rem;
}

h1,
p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

label {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea{
  background-color: white;
  color: black;
}
button {
  border: none;
}

.applyBtnContainer {
  width: 100%;
}

@media (min-width: 576px) {
  .applyBtnContainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .applyBtnContainer {
    max-width: 720px;
  }
}

.header {
  padding: 0 0.625rem;
  margin-bottom: 1.875rem;
}

.description {
  font-style: italic;
  font-weight: 200;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.clue {
  margin-left: 0.25rem;
  font-size: 0.9rem;
  color: #e4e4e4;
}

.text-center {
  text-align: center;
  color: black;
}
.description-text-center{
  color: black;
}
/* form */

form {
  background: rgb(82, 82, 228);
  padding: 2.5rem 0.625rem;
  border-radius: 0.25rem;
}

@media (min-width: 480px) {
  form {
    padding: 2.5rem;
  }
}

.form-group {
  margin: 0 auto 1.25rem auto;
  padding: 0.25rem;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.input-checkbox {
  display: inline-block;
  margin-right: 0.625rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  width: 120px;
}
.input-radio{
  display: inline-block;
  margin-right: 0.625rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  width: 120px;
}
.input-textarea {
  min-height: 120px;
  width: 100%;
  padding: 0.625rem;
  resize: vertical;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background: Green;
  color: White;
  border-radius: 2px;
  cursor: pointer;
}
