.animation{
  color: white;
  font-weight: bold;
  font-size: 25px;
}

@media screen and (max-width: 480px) {
  .animation{
    color: white;
    font-weight: bold;
    font-size: 17px;
}
}