.SidebarContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: left;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
}

.CloseIcon {
  color: #fff;
  margin-right: 40px;
}

.Icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}