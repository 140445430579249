@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  font-family: "Encode Sans Expanded", sans-serif;
}
html,
body {
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.animation{
  color: white;
  font-weight: bold;
  font-size: 25px;
}

@media screen and (max-width: 480px) {
  .animation{
    color: white;
    font-weight: bold;
    font-size: 17px;
}
}
.sidebar-contact{
  position:fixed;
  top:60%;
  right:-350px;
  z-index: 100;
  transform:translateY(-50%);
  width:350px;
  height:auto;
  padding:40px;
  background:#fff;
  box-shadow: 0 20px 50px rgba(0,0,0,.5);
  box-sizing:border-box;
  transition:0.5s;
}
.sidebar-contact.active{
  right:0;
}

.sidebar-contact h2{
  margin:0 0 20px;
  padding:0;
}

.toggle{
  position:absolute;
  text-align:center;
  cursor:pointer;
  top:0;
  right:350px;
  line-height:48px;
}
.toggle:before{
  
  font-family:fontAwesome;
  font-size:18px;
  color:#fff;
}

@media(max-width:768px)
{
  .sidebar-contact{
    width:100%;
    height:100%;
    left:-100%;
  }
  .sidebar-contact .toggle{
    top:50%;
    transform:translateY(-50%);
    transition:0.5s;
  }
  .sidebar-contact.active .toggle
  {
    top:0;
    right:0;
    transform:translateY(0);
  }
  .scroll{
    width:100%;
    height:100%;
    overflow-y:auto;
  }
}
.Nav {
  background: #000;
  height: 80px;
  margin-top: -80px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

.NavbarContainer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;

  padding: 0 24px;
  max-width: 1100px;
}

.NavLogo {
  color: red;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
  margin-left: 80px;
  font-weight: bold;
  text-decoration: none;
}

.MobileIcon {
  display: none;
  margin-top: -10px;
}

.NavMenu {
  display: flex;
  margin-top: 20px;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
}

.NavItem {
  height: 80px;
  text-decoration: none;
}
.dropdownBar {
  margin-top: 20px;
}
.nav-link{
  color: #fff;
}
.NavLinks {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.NavLinks::active {
  border-bottom: 3px solid #01bf71;
}

.NavBtn {
  display: flex;
  align-items: center;
}

.NavBtnLink {
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.NavBtnLink::hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: #010606;
}

@media screen and (max-width: 960px) {
  .Nav {
    transition: 0.8s all ease;
  }
}

@media screen and (max-width: 480px) {
  .NavLogo {
    margin-left: 130px;
  }
}
@media screen and (max-width: 768px) {
  .MobileIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
  .NavMenu {
    display: none;
  }
  .NavBtn {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .NavLogo {
    margin-left: 10px;
  }
}
.SidebarContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: left;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
}

.CloseIcon {
  color: #fff;
  margin-right: 40px;
}

.Icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}
.faqcontainer {
  display: flex;
  width: 80%;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
  height: 500px;
}

.cardContainerFirst {
  width: 650px;
  height: 370px;
}
.cardContainerSecond {
  margin-left: 20px;
  width: 650px;
  height: 370px;
}

.cardOne {
  margin-top: 25px;
}
.cardTwo {
  margin-top: 25px;
}

.cardThree {
  margin-top: 25px;
}

.cardFour {
  margin-top: 25px;
}
.cardSix {
  margin-top: 25px;
}
.cardSeven {
  margin-top: 25px;
}
.cardEight {
  margin-top: 25px;
}
.cardNine {
  margin-top: 25px;
}

@media screen and (max-width: 480px) {
  .faqcontainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 120px !important;
  }
  .cardContainerFirst {
    flex: 1 1;
    flex-wrap: wrap;
    justify-content: space-evenly !important;
  }
  .cardContainerSecond {
    flex: 1 1;
    flex-wrap: wrap;
    margin-left: 0px;
    margin-top: 25px;
    justify-content: space-evenly !important;
  }

  .cardContainerFirst > Card {
    flex-basis: 200px;
  }
  .cardContainerSecond > Card {
    flex-basis: 200px;
  }

  .cardOne {
    margin-top: 20px !important;
  }
  .cardTwo {
    margin-top: 20px !important;
  }

  .cardThree {
    margin-top: 20px !important;
  }
  .cardEight {
    margin-top: -37px !important;
  }
}

@media screen and (max-width: 768px) {
  .faqcontainer {
    width: 70%;
    margin: 0 auto;
    height: 700px;
  }

  .cardContainerFirst {
    position: relative;
    width: 350px;
    height: 500px;
  }

  .cardContainerSecond {
    position: relative;
    width: 350px;
    height: 500px;
  }
  .cardOne {
    margin-top: 50px;
  }
  .cardSeven {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 1024px) {
  .faqcontainer {
    width: 70%;
    margin: 0 auto;
  }
  .cardContainerFirst {
    position: relative;
    width: 350px;
    height: 500px;
  }

  .cardContainerSecond {
    position: relative;
    width: 350px;
    height: 500px;
  }
  .cardTwo {
    margin-top: 45px;
  }
  .cardThree {
    margin-top: 43px;
  }
  .cardSeven {
    margin-top: 22px;
  }
  .cardEight {
    margin-top: 43px;
  }
}

.clientlogodiv{
    background-color: white;
    height: 400px;
    
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2);
}

.clientlogo {
  width: 280px;
  height: 200px;
  object-fit: contain;
}
.carouseldiv {
  margin-bottom: 70px;
}

@media screen and (max-width: 1024px) {
  .clientlogo {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .clientlogo {
    width: 150px;
    height: 200px;
    margin-right: 5px;
    object-fit: contain;
  }
}

@media screen and (max-width: 480px) {
  .clientlogodiv{
    background-color: white;
    height: 600px;
    margin-top: 100px;
}
}
.testimonialMainContainer {
  background-color: black;
  height: 500px;
  width: 100%;
}

.reviewContainer {
  display: flex;
  margin-bottom: 50px !important;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
  margin-left: 10px;
}

.testimonialCard {
  margin-right: 10px;
  width: 550px;
  margin-top: 40px;
  height: 350px;
  padding: 20px;
}

.cardPara {
  margin-top: 10px;
  width: 450px;
  height: 200px;
}

@media screen and (max-width: 1024px) {
  .reviewContainer {
    display: flex;
    margin-bottom: 100px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .reviewContainer {
    display: flex;
    margin-bottom: 100px;
    margin-left: 10px;
  }
  .cardPara {
    margin-top: 10px;
    font-size: 15px;
    width: 300px;
  }
  .testimonialCard {
    margin-right: 10px;
    width: 550px;
    margin-top: 40px;
    height: 350px;
    padding: 20px;
  }
}

@media screen and (max-width: 480px) {
  .testimonialMainContainer {
    background-color: black;
    height: 900px;
    width: 100%;
  }
  .reviewContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    margin-left: 22px;
  }
  .cardPara {
    margin-top: 10px;
    font-size: 15px;
    width: 300px;
  }
  .testimonialCard {
    margin-right: 10px;
    width: 330px;
    margin-top: 40px;
    height: 350px;
    padding: 20px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-leftt {
  position: absolute;
  text-align: left;
  padding-top: 50px;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centeredd {
  position: absolute;
  top: 90%;
  left: 29.5%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingwebdesignone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingwebdesigntwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerwebdesignone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerwebdesigntwo {
  font-weight: bold;
}
.imagewebdesignone {
  width: 700px;
  margin-top: 15px;
}
.imagewebdesigntwo {
  width: 700px;
  margin-top: 50px;
  padding-left: 40px;
}
.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color: blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-leftt {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centeredd {
    position: absolute;
    top: 90%;
    left: 39.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingwebdesignone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingwebdesigntwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerwebdesignone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagewebdesignone {
    width: 500px;
    height: 271px;
    margin-top: 65px;
  }
  .imagewebdesigntwo {
    width: 500px;
    height: 271px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 80px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-leftt {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centeredd {
    position: absolute;
    top: 90%;
    left: 49%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
  .briefingwebdesignone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingwebdesigntwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerwebdesignone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebdesigntwo {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imagewebdesignone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }
  .imagewebdesigntwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner {
    display: none;
  }
  .top-leftt {
    display: none;
  }
  .centeredd {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
  .briefingwebdesignone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingwebdesigntwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerwebdesignone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebdesigntwo {
    font-weight: bold;
    text-align: center;
  }
  .imagewebdesignone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 217px;
    margin-top: 30px;
  }
  .imagewebdesigntwo {
    display: block;
    object-fit: fill;
    height: 217px;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
  }

  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.submitBtn {
  width: 200px;
  margin-right: 130px;
}
.modalheading {
  margin-left: 100px;
}
.feedbackTxt{
  color: black;
}
.successgif{
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .submitBtn {
    margin-right: 140px;
  }
}
@media screen and (max-width: 480px) {
  .submitBtn {
    margin-right: 85px;
  }
  .modalheading {
    margin-left: 50px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingwebsitedevone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingwebsitedevtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerwebsitedevone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerwebsitedevtwo {
  font-weight: bold;
}
.imagewebsitedevone {
  width: 700px;
  margin-top: 15px;
}
.imagewebsitedevtwo {
  width: 700px;
  margin-top: 50px;
  padding-left: 40px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}


.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingwebsitedevone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingwebsitedevtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerwebsitedevone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagewebsitedevone {
    width: 500px;
    height: 271px;
    margin-top: 65px;
  }
  .imagewebsitedevtwo {
    width: 500px;
    height: 271px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 80px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingwebsitedevone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingwebsitedevtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerwebsitedevone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebsitedevtwo {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imagewebsitedevone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }
  .imagewebsitedevtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingwebsitedevone {
     display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingwebsitedevtwo {
     display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerwebsitedevone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebsitedevtwo {
    font-weight: bold;
    text-align: center;
  }
  .imagewebsitedevone {
   display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 217px;
    margin-top: 30px;
  }
  .imagewebsitedevtwo {
    display: block;
    object-fit: fill;
    height: 217px;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingwebmaintenanceone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingwebmaintenancetwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerwebmaintenanceone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerwebmaintenancetwo {
  font-weight: bold;
}
.imagewebmaintenanceone {
  width: 700px;
  margin-top: 15px;
}
.imagewebmaintenancetwo {
  width: 700px;
  margin-top: 50px;
  padding-left: 40px;
}
.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}


.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingwebmaintenanceone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingwebmaintenancetwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerwebmaintenanceone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagewebmaintenanceone {
    width: 500px;
    height: 271px;
    margin-top: 65px;
  }
  .imagewebmaintenancetwo {
    width: 500px;
    height: 271px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 80px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingwebmaintenanceone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingwebmaintenancetwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerwebmaintenanceone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebmaintenancetwo {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imagewebmaintenanceone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }
  .imagewebmaintenancetwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingwebmaintenanceone {
     display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingwebmaintenancetwo {
    display: flex;
    flex-direction: column;
    min-width: 380px !important;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerwebmaintenanceone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebmaintenancetwo {
    font-weight: bold;
    text-align: center;
  }
  .imagewebmaintenanceone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 217px;
    margin-top: 30px;
  }
  .imagewebmaintenancetwo {
    display: block;
    object-fit: fill;
    height: 217px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}

.briefingwebhostingone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.headerwebhostingone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.imagewebhostingone {
  width: 700px;
  margin-top: -85px;
  padding: 100px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingwebhostingone {
    min-width: 500px;
    margin-top: 30px;
  }
  .headerwebhostingone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagewebhostingone {
    width: 500px;
    height: 451px;
    margin-top: -45px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
   .briefingwebhostingone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headerwebhostingone {
    font-weight: bold;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .imagewebhostingone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
 
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
   .briefingwebhostingone {
   display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .headerwebhostingone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .imagewebhostingone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -55px;
  }
  
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}

.briefingwebapplicationone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.headerwebapplicationone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.imagewebapplicationone {
  width: 700px;
  margin-top: -85px;
  padding: 100px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingwebapplicationone {
    min-width: 500px;
    margin-top: 30px;
  }
  .headerwebapplicationone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagewebapplicationone {
    width: 500px;
    height: 451px;
    margin-top: -45px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
   .briefingwebapplicationone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headerwebapplicationone {
    font-weight: bold;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .imagewebapplicationone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
   .briefingwebapplicationone {
   display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .headerwebapplicationone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .imagewebapplicationone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -55px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingcustomone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingcustomtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headercustomone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headercustomtwo {
  font-weight: bold;
}
.imagecustomone {
  width: 700px;
  margin-top: -125px;
  padding: 100px;
}
.imagecustomtwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingcustomone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingcustomtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headercustomone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagecustomone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imagecustomtwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingcustomone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingcustomtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headercustomone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headercustomtwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imagecustomone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imagecustomtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingcustomone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingcustomtwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headercustomone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headercustomtwo {
    font-weight: bold;
    text-align: center;
  }
  .imagecustomone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 354px;
    margin-top: -100px;
  }
  .imagecustomtwo {
    display: block;
    object-fit: fill;
    width: 360px;
    height: 354px;
    margin-left: auto;
    margin-right: auto;
    
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingwordpressone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingwordpresstwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerwordpressone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerwordpresstwo {
  font-weight: bold;
}
.imagewordpressone {
  width: 700px;
  margin-top: -125px;
  padding: 100px;
}
.imagewordpresstwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingwordpressone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingwordpresstwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerwordpressone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagewordpressone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imagewordpresstwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingwordpressone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingwordpresstwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerwordpressone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerwordpresstwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imagewordpressone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imagewordpresstwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingwordpressone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingwordpresstwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerwordpressone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerwordpresstwo {
    font-weight: bold;
    text-align: center;
  }
  .imagewordpressone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imagewordpresstwo {
    display: block;
    object-fit: fill;
    width: 390px;
    height: 354px;
    margin-left: auto;
    margin-right: auto;
   
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingandroidone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingandroidtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerandroidone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerandroidtwo {
  font-weight: bold;
}
.imageandroidone {
  width: 700px;
  margin-top: -125px;
  padding: 140px;
}
.imageandroidtwo {
  width: 700px;
  margin-top: -70px;
  padding: 130px;
}
.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}


.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
   .briefingandroidone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingandroidtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerandroidone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageandroidone {
    width: 550px;
    height: 501px;
    margin-top: -65px;
  }
  .imageandroidtwo {
    width: 550px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
    .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingandroidone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingandroidtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerandroidone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerandroidtwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imageandroidone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imageandroidtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
   .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingandroidone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingandroidtwo {
     display: flex;
    flex-direction: column;
    min-width: 380px !important;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerandroidone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerandroidtwo {
    font-weight: bold;
    text-align: center;
  }
  .imageandroidone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 394px;
    margin-top: -100px;
  }
  .imageandroidtwo {
    display: block;
    object-fit: fill;
    width: 390px;
    height: 394px;
    margin-left: auto;
    margin-right: auto;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingandroidone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingandroidtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerandroidone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerandroidtwo {
  font-weight: bold;
}
.imageandroidone {
  width: 700px;
  margin-top: -125px;
  padding: 140px;
}
.imageandroidtwo {
  width: 700px;
  margin-top: -70px;
  padding: 130px;
}
.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}


.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
   .briefingandroidone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingandroidtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerandroidone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageandroidone {
    width: 550px;
    height: 501px;
    margin-top: -65px;
  }
  .imageandroidtwo {
    width: 550px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
    .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingandroidone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingandroidtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerandroidone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerandroidtwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imageandroidone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imageandroidtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
   .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingandroidone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingandroidtwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerandroidone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerandroidtwo {
    font-weight: bold;
    text-align: center;
  }
  .imageandroidone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 394px;
    margin-top: -100px;
  }
  .imageandroidtwo {
    display: block;
    object-fit: fill;
    width: 390px;
    height: 394px;
    margin-left: auto;
    margin-right: auto;
    
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingEcomWebDevone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingEcomWebDevtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerEcomWebDevone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerEcomWebDevtwo {
  font-weight: bold;
}
.imageEcomWebDevone {
  width: 700px;
  margin-top: -125px;
  padding: 100px;
}
.imageEcomWebDevtwo {
  width: 700px;
  margin-top: -135px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingEcomWebDevone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingEcomWebDevtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerEcomWebDevone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageEcomWebDevone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imageEcomWebDevtwo {
    width: 550px;
    height: 601px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingEcomWebDevone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingEcomWebDevtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerEcomWebDevone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerEcomWebDevtwo {
    font-weight: bold;
    text-align: center;
    margin-top: -120px;
    margin-bottom: 20px;
  }
  .imageEcomWebDevone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imageEcomWebDevtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -145px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingEcomWebDevone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingEcomWebDevtwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerEcomWebDevone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerEcomWebDevtwo {
    font-weight: bold;
    text-align: center;
  }
  .imageEcomWebDevone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imageEcomWebDevtwo {
    display: block;
    object-fit: fill;
    height: 487px;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}



.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingseoone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingseotwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerseoone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerseotwo {
  font-weight: bold;
}
.imageseoone {
  width: 700px;
  margin-top: -120px;
  padding: 100px;
}
.imageseotwo {
  width: 700px;
  margin-top: -10px;
  padding: 50px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingseoone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingseotwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerseoone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageseoone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imageseotwo {
    width: 500px;
    height: 371px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingseoone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingseotwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerseoone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerseotwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imageseoone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imageseotwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: -25px;
    margin-bottom: 40px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingseoone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingseotwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerseoone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerseotwo {
    font-weight: bold;
    text-align: center;
  }
  .imageseoone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imageseotwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 380px;
    height: 317px;
    margin-top: -20px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingecomwebhostingone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingecomwebhostingtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerecomwebhostingone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerecomwebhostingtwo {
  font-weight: bold;
}
.imageecomwebhostingone {
  width: 700px;
  margin-top: -100px;
  padding: 100px;
}
.imageecomwebhostingtwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingecomwebhostingone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingecomwebhostingtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerecomwebhostingone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageecomwebhostingone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imageecomwebhostingtwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingecomwebhostingone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingecomwebhostingtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerecomwebhostingone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerecomwebhostingtwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imageecomwebhostingone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imageecomwebhostingtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingecomwebhostingone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingecomwebhostingtwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerecomwebhostingone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerecomwebhostingtwo {
    font-weight: bold;
    text-align: center;
  }
  .imageecomwebhostingone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imageecomwebhostingtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 384px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingwebmaintenanceone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingwebmaintenancetwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerwebmaintenanceone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerwebmaintenancetwo {
  font-weight: bold;
}
.imagewebmaintenanceone {
  width: 700px;
  margin-top: 15px;
}
.imagewebmaintenancetwo {
  width: 700px;
  margin-top: 50px;
  padding-left: 40px;
}
.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}


.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingwebmaintenanceone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingwebmaintenancetwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerwebmaintenanceone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagewebmaintenanceone {
    width: 500px;
    height: 271px;
    margin-top: 65px;
  }
  .imagewebmaintenancetwo {
    width: 500px;
    height: 271px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 80px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingwebmaintenanceone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingwebmaintenancetwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerwebmaintenanceone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebmaintenancetwo {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .imagewebmaintenanceone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }
  .imagewebmaintenancetwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 25px;
  }
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingwebmaintenanceone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingwebmaintenancetwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerwebmaintenanceone {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .headerwebmaintenancetwo {
    font-weight: bold;
    text-align: center;
  }
  .imagewebmaintenanceone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 217px;
    margin-top: 30px;
  }
  .imagewebmaintenancetwo {
     display: block;
    object-fit: fill;
    height: 217px;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingpaymentone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingpaymenttwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerpaymentone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerpaymenttwo {
  font-weight: bold;
}
.imagepaymentone {
  width: 700px;
  margin-top: -125px;
  padding: 100px;
}
.imagepaymenttwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingpaymentone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingpaymenttwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerpaymentone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagepaymentone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imagepaymenttwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingpaymentone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingpaymenttwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerpaymentone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerpaymenttwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imagepaymentone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imagepaymenttwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingpaymentone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingpaymenttwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerpaymentone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerpaymenttwo {
    font-weight: bold;
    text-align: center;
  }
  .imagepaymentone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imagepaymenttwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}

.briefingsslone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.headersslone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.imagesslone {
  width: 700px;
  margin-top: -85px;
  padding: 100px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingsslone {
    min-width: 500px;
    margin-top: 30px;
  }
  .headersslone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagesslone {
    width: 500px;
    height: 451px;
    margin-top: -45px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
   .briefingsslone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headersslone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .imagesslone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
 
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
   .briefingsslone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .headersslone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .imagesslone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -55px;
  }
  
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.contactContainer {
  display: flex;
  justify-content: center;
  margin-top: -130px;
}
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v54/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.successgif {
  display: flex;
  justify-content: center;
}

.input-field {
  margin-bottom: 20px;
}
.contact_wrapper {
  display: flex;
  margin-top: 170px;
  margin-bottom: 80px;
  background: white;
  padding: 40px 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 700px;
}

.headertag {
  text-align: center;
  font-size: 40px;
  margin-top: 100px;
}
.contact_info {
  position: relative;
  width: 230px;
  flex: 0 0 230px;
  background: #095a54;
  color: #f6f6f6;
  margin-left: calc(-230px / 2 - 30px);
  padding: 25px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 24px;
  margin: 0px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}
.icons_wrapp {
  margin: 0px;
  padding: 0px;
  margin-top: 60px;
  list-style: none;
  margin-bottom: 30px;
}
.soci_wrap {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 25px;
}

.contact_msg {
  width: calc(100% - 230px / 2 - 55px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 230px / 2 - 55px);
  padding: 25px 30px;
  padding-bottom: 0px;
}
.title {
  font-size: 24px;
  margin: 0px;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #095a54;
}

.input_group {
  position: relative;
  margin-bottom: 25px;
  padding: 0 15px;
}

.input {
  width: 100%;
  min-height: 30px;
  padding: 5px 0px;
  border: none;
  font-size: 18px;
  color: #444;
  outline: none;
  font-weight: 400;
  border-bottom: solid 2px #e9e9e9;
}

.placeholder {
  position: absolute;
  left: 15px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #bbb;
  font-weight: 400;
  transition: all 0.3s ease;
  pointer-events: none;
}

.btn {
  padding: 12px;
  border: none;
  border-radius: 0px;
  background-color: #21095a;
  color: white;
  min-width: 120px;
  outline: none;
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: #06423e;
}
.btn:active {
  transform: scale(0.95);
}

@media screen and (max-width: 440px) {
  .contact_info {
    position: relative;
    width: 300px;
    flex: 0 0 230px;
    background: #095a54;
    color: #f6f6f6;
    padding: 25px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
    margin-left: 60px !important;
  }
   .contact_msg {
    width: 500px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
    margin-left: -80px !important;
  }
}
@media screen and (max-width: 768px) {
  .contact_wrapper {
    display: flex;
    flex-direction: column;
    width: 550px !important;
    margin-left: -10px !important;
  }
  .contact_info {
    position: relative;
    width: 300px;
    flex: 0 0 230px;
    background: #095a54;
    color: #f6f6f6;
    padding: 25px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
    margin-left: 100px;
  }
  .contact_msg {
    width: 500px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
    margin-left: -50px;
  }
  .title {
    margin-left: 160px;
  }
}

@media screen and (max-width: 1024px) {
  .contact_wrapper {
    display: flex;
    margin-bottom: 80px;
    background: white;
    padding: 40px 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 700px;
    margin-left: 100px;
  }
}

.careerContainer {
  margin-top: 90px;
}
.p-large {
  text-align: center;
  font: 300 20px/28px "Open Sans", Arial, Helvetica, sans-serif;
  max-width: 960px;
  margin: 0 auto;
  color: blue;
}
.successgif{
  display: flex;
  justify-content: center;
}
ul.job-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.job-list > li.job-preview {
  background: #fff;
  border: 1px solid #d7e2e9;
  border-radius: 0.4rem;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  float: left;
  width: 100%;
  transition: all 0.3s ease 0s;
}

ul.job-list > li.job-preview:hover {
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.job-title {
  margin-top: 0.6rem;
  color: black;
}

.company {
  color: #96a4b1;
}

.job-preview .btn {
  margin-top: 1.1rem;
}

.btn-apply {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 1px;
  background-color: transparent;
  color: #393a5f;
  border: 2px solid #393a5f;
  padding: 0.6rem 2rem;
  border-radius: 2rem;
}

.btn-apply:hover {
  background-color: #393a5f;
  color: #fff;
  border: 2px solid #393a5f;
}

@media (max-width: 575px) {
  .job-preview .content {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  #navbar-main {
    background: transparent;
    border-bottom: 1px solid transparent;
  }

  #navbar-main .navbar-brand {
    color: #fff;
    opacity: 0.8;
    padding: 0.95rem 1.2rem;
  }

  #navbar-main .navbar-brand:hover,
  #navbar-main .navbar-brand:focus {
    opacity: 1;
  }

  #navbar-main .navbar-nav > li.nav-item > a {
    color: #fff;
    opacity: 0.8;
    padding: 0.95rem 1.2rem;
  }

  #navbar-main .navbar-nav > li.nav-item > a:hover,
  #navbar-main .navbar-nav > li.nav-item > a:focus {
    opacity: 1;
  }
}
:root{
  font-family: 'Varela Round', sans-serif;}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.careerPage {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: white;
  margin: 0;
}

/* mobile friendly alternative to using background-attachment: fixed */
.careerPage::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: var(--color-darkblue);
  background-image: 
    url(/static/media/career-background.1d7fe5c4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

h1 {
  font-weight: 400;
  line-height: 1.2;
}

p {
  font-size: 1.125rem;
}

h1,
p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

label {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea{
  background-color: white;
  color: black;
}
button {
  border: none;
}

.applyBtnContainer {
  width: 100%;
}

@media (min-width: 576px) {
  .applyBtnContainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .applyBtnContainer {
    max-width: 720px;
  }
}

.header {
  padding: 0 0.625rem;
  margin-bottom: 1.875rem;
}

.description {
  font-style: italic;
  font-weight: 200;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.clue {
  margin-left: 0.25rem;
  font-size: 0.9rem;
  color: #e4e4e4;
}

.text-center {
  text-align: center;
  color: black;
}
.description-text-center{
  color: black;
}
/* form */

form {
  background: rgb(82, 82, 228);
  padding: 2.5rem 0.625rem;
  border-radius: 0.25rem;
}

@media (min-width: 480px) {
  form {
    padding: 2.5rem;
  }
}

.form-group {
  margin: 0 auto 1.25rem auto;
  padding: 0.25rem;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.input-checkbox {
  display: inline-block;
  margin-right: 0.625rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  width: 120px;
}
.input-radio{
  display: inline-block;
  margin-right: 0.625rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  width: 120px;
}
.input-textarea {
  min-height: 120px;
  width: 100%;
  padding: 0.625rem;
  resize: vertical;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background: Green;
  color: White;
  border-radius: 2px;
  cursor: pointer;
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingperformanceone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingperformancetwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerperformanceone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerperformancetwo {
  font-weight: bold;
}
.imageperformanceone {
  width: 700px;
  margin-top: -125px;
  padding: 100px;
}
.imageperformancetwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color: #000;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color:blue;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingperformanceone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingperformancetwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerperformanceone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageperformanceone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imageperformancetwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingperformanceone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingperformancetwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerperformanceone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerperformancetwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imageperformanceone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imageperformancetwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingperformanceone {
     display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingperformancetwo {
     display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerperformanceone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerperformancetwo {
    font-weight: bold;
    text-align: center;
  }
  .imageperformanceone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imageperformancetwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingfbone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingfbtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerfbone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerfbtwo {
  font-weight: bold;
}
.imagefbone {
  width: 700px;
  margin-top: -125px;
  padding: 100px;
}
.imagefbtwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingfbone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingfbtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerfbone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagefbone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imagefbtwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingfbone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingfbtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerfbone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerfbtwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imagefbone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imagefbtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingfbone {
   display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingfbtwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerfbone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerfbtwo {
    font-weight: bold;
    text-align: center;
  }
  .imagefbone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imagefbtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}



.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}

.briefingsocialmediamarketone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.headersocialmediamarketone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.imagesocialmediamarketone {
  width: 700px;
  margin-top: -85px;
  padding: 100px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingsocialmediamarketone {
    min-width: 500px;
    margin-top: 30px;
  }
  .headersocialmediamarketone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagesocialmediamarketone {
    width: 500px;
    height: 451px;
    margin-top: -45px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
   .briefingsocialmediamarketone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headersocialmediamarketone {
    font-weight: bold;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .imagesocialmediamarketone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
 
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
   .briefingsocialmediamarketone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .headersocialmediamarketone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .imagesocialmediamarketone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -55px;
  }
  
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingpayone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingpaytwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headerpayone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headerpaytwo {
  font-weight: bold;
}
.imagepayone {
  width: 700px;
  margin-top: -100px;
  padding: 100px;
}
.imagepaytwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color: blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }

  .briefingpayone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingpaytwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headerpayone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagepayone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imagepaytwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }

  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
  .briefingpayone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingpaytwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headerpayone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headerpaytwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imagepayone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imagepaytwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner {
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
  .briefingpayone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingpaytwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headerpayone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headerpaytwo {
    font-weight: bold;
    text-align: center;
  }
  .imagepayone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imagepaytwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingsocialmediapageone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingsocialmediapagetwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headersocialmediapageone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headersocialmediapagetwo {
  font-weight: bold;
}
.imagesocialmediapageone {
  width: 700px;
  margin-top: -90px;
  padding: 100px;
}
.imagesocialmediapagetwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingsocialmediapageone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingsocialmediapagetwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headersocialmediapageone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagesocialmediapageone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imagesocialmediapagetwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingsocialmediapageone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingsocialmediapagetwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headersocialmediapageone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headersocialmediapagetwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imagesocialmediapageone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imagesocialmediapagetwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingsocialmediapageone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingsocialmediapagetwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headersocialmediapageone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headersocialmediapagetwo {
    font-weight: bold;
    text-align: center;
  }
  .imagesocialmediapageone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -100px;
  }
  .imagesocialmediapagetwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}

.briefingoptimizationone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.headeroptimizationone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.imageoptimizationone {
  width: 700px;
  margin-top: -85px;
  padding: 100px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingoptimizationone {
    min-width: 500px;
    margin-top: 30px;
  }
  .headeroptimizationone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageoptimizationone {
    width: 520px;
    height: 451px;
    margin-top: -45px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
   .briefingoptimizationone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headeroptimizationone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .imageoptimizationone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: center;
    margin-top: -50px;
  }
 
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
   .briefingoptimizationone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .headeroptimizationone {
    font-weight: bold;
    text-align: center;
    margin-top: -40px;
    margin-bottom: 20px;
  }
  .imageoptimizationone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -75px;
  }
  
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}

.briefinggoogleadone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.headergoogleadone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.imagegoogleadone {
  width: 700px;
  margin-top: -85px;
  padding: 100px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefinggoogleadone {
    min-width: 500px;
    margin-top: 30px;
  }
  .headergoogleadone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagegoogleadone {
    width: 500px;
    height: 451px;
    margin-top: -45px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
   .briefinggoogleadone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headergoogleadone {
    font-weight: bold;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .imagegoogleadone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
 
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
   .briefinggoogleadone {
     display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .headergoogleadone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .imagegoogleadone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -55px;
  }
  
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}

.briefingemailmarketingone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.headeremailmarketingone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}

.imageemailmarketingone {
  width: 700px;
  margin-top: -85px;
  padding: 100px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}

.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefingemailmarketingone {
    min-width: 500px;
    margin-top: 30px;
  }
  .headeremailmarketingone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imageemailmarketingone {
    width: 500px;
    height: 451px;
    margin-top: -45px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
   .briefingemailmarketingone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .headeremailmarketingone {
    font-weight: bold;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .imageemailmarketingone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
 
  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
   .briefingemailmarketingone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .headeremailmarketingone {
    font-weight: bold;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }
  .imageemailmarketingone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 390px;
    height: 354px;
    margin-top: -55px;
  }
  
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}

