.Nav {
  background: #000;
  height: 80px;
  margin-top: -80px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

.NavbarContainer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;

  padding: 0 24px;
  max-width: 1100px;
}

.NavLogo {
  color: red;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
  margin-left: 80px;
  font-weight: bold;
  text-decoration: none;
}

.MobileIcon {
  display: none;
  margin-top: -10px;
}

.NavMenu {
  display: flex;
  margin-top: 20px;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
}

.NavItem {
  height: 80px;
  text-decoration: none;
}
.dropdownBar {
  margin-top: 20px;
}
.nav-link{
  color: #fff;
}
.NavLinks {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.NavLinks::active {
  border-bottom: 3px solid #01bf71;
}

.NavBtn {
  display: flex;
  align-items: center;
}

.NavBtnLink {
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.NavBtnLink::hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: #010606;
}

@media screen and (max-width: 960px) {
  .Nav {
    transition: 0.8s all ease;
  }
}

@media screen and (max-width: 480px) {
  .NavLogo {
    margin-left: 130px;
  }
}
@media screen and (max-width: 768px) {
  .MobileIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
  .NavMenu {
    display: none;
  }
  .NavBtn {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .NavLogo {
    margin-left: 10px;
  }
}