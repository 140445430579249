* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  font-family: "Encode Sans Expanded", sans-serif;
}
html,
body {
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
