.contactContainer {
  display: flex;
  justify-content: center;
  margin-top: -130px;
}
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v54/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.successgif {
  display: flex;
  justify-content: center;
}

.input-field {
  margin-bottom: 20px;
}
.contact_wrapper {
  display: flex;
  margin-top: 170px;
  margin-bottom: 80px;
  background: white;
  padding: 40px 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 700px;
}

.headertag {
  text-align: center;
  font-size: 40px;
  margin-top: 100px;
}
.contact_info {
  position: relative;
  width: 230px;
  flex: 0 0 230px;
  background: #095a54;
  color: #f6f6f6;
  margin-left: calc(-230px / 2 - 30px);
  padding: 25px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 24px;
  margin: 0px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}
.icons_wrapp {
  margin: 0px;
  padding: 0px;
  margin-top: 60px;
  list-style: none;
  margin-bottom: 30px;
}
.soci_wrap {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 25px;
}

.contact_msg {
  width: calc(100% - 230px / 2 - 55px);
  flex: 0 0 calc(100% - 230px / 2 - 55px);
  padding: 25px 30px;
  padding-bottom: 0px;
}
.title {
  font-size: 24px;
  margin: 0px;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #095a54;
}

.input_group {
  position: relative;
  margin-bottom: 25px;
  padding: 0 15px;
}

.input {
  width: 100%;
  min-height: 30px;
  padding: 5px 0px;
  border: none;
  font-size: 18px;
  color: #444;
  outline: none;
  font-weight: 400;
  border-bottom: solid 2px #e9e9e9;
}

.placeholder {
  position: absolute;
  left: 15px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #bbb;
  font-weight: 400;
  transition: all 0.3s ease;
  pointer-events: none;
}

.btn {
  padding: 12px;
  border: none;
  border-radius: 0px;
  background-color: #21095a;
  color: white;
  min-width: 120px;
  outline: none;
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: #06423e;
}
.btn:active {
  transform: scale(0.95);
}

@media screen and (max-width: 440px) {
  .contact_info {
    position: relative;
    width: 300px;
    flex: 0 0 230px;
    background: #095a54;
    color: #f6f6f6;
    padding: 25px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
    margin-left: 60px !important;
  }
   .contact_msg {
    width: 500px;
    flex: 0 0 calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
    margin-left: -80px !important;
  }
}
@media screen and (max-width: 768px) {
  .contact_wrapper {
    display: flex;
    flex-direction: column;
    width: 550px !important;
    margin-left: -10px !important;
  }
  .contact_info {
    position: relative;
    width: 300px;
    flex: 0 0 230px;
    background: #095a54;
    color: #f6f6f6;
    padding: 25px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
    margin-left: 100px;
  }
  .contact_msg {
    width: 500px;
    flex: 0 0 calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
    margin-left: -50px;
  }
  .title {
    margin-left: 160px;
  }
}

@media screen and (max-width: 1024px) {
  .contact_wrapper {
    display: flex;
    margin-bottom: 80px;
    background: white;
    padding: 40px 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 700px;
    margin-left: 100px;
  }
}
