.navpagecontainer {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  text-align: left;
  top: 150px;
  width: 600px;
  left: 30%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.centered {
  position: absolute;
  top: 80%;
  left: 23%;
  font-size: 20px;
  width: 700px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: white;
}
.webbanner {
  width: 100%;
  height: 300px;
  margin: 0%;
}
.servicesHeading {
  font-weight: bold;
}
.briefContainerOne {
  display: flex;
  text-align: left;
  padding-top: 15px;
  justify-content: center;
  margin: 0 auto;
}
.briefContainerTwo {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
}
.briefingcustomone {
  min-width: 600px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}
.briefingcustomtwo {
  min-width: 600px;
  margin-top: 55px;
  padding-left: 40px;
}

.headercustomone {
  font-weight: bold;
  float: right;
  margin-top: 20px;
  margin-bottom: 5px;
}
.headercustomtwo {
  font-weight: bold;
}
.imagecustomone {
  width: 700px;
  margin-top: -125px;
  padding: 100px;
}
.imagecustomtwo {
  width: 700px;
  margin-top: -70px;
  padding: 120px;
}

.serviceBtn {
  margin-bottom: 30px;
  text-align: center;
}
.serviceButton {
  font-weight: bold;
  background-color: red;
}
.serviceButton:hover {
  background-color: royalblue;
}
.additionalServiceHeading {
  text-align: center;
  padding-top: 25px;
}
.cardLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardLayoutAdditional {
  display: flex;
  margin-bottom: 15px;
}

.cardBoxAdditional {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 450ms;
}
.cardBoxAdditional:hover {
  transform: scale(1.06);
}

.imageLogo {
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
}
.boxPara {
  color:blue;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;
}
.boxSubPara {
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 1024px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 40%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 30.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefingcustomone {
    min-width: 500px;
    margin-top: 30px;
  }
  .briefingcustomtwo {
    min-width: 500px;
    margin-top: 60px;
    padding-left: 80px;
  }
  .headercustomone {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .imagecustomone {
    width: 500px;
    height: 501px;
    margin-top: -65px;
  }
  .imagecustomtwo {
    width: 500px;
    height: 501px;
    margin-top: -55px;
  }

  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .cardLayoutAdditional {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cardBoxAdditional {
    height: 250px;
    width: 300px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 768px) {
  .top-left {
    position: absolute;
    top: 150px;
    width: 600px;
    left: 50%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  .centered {
    position: absolute;
    top: 80%;
    left: 37.5%;
    font-size: 20px;
    width: 700px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
   .briefingcustomone {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .briefingcustomtwo {
    display: flex;
    flex-direction: column;
    min-width: 650px;
    text-align: center;
    margin-top: 10px;
    margin-left: 8px;
    margin-right: auto;
    width: 50%;
  }
  .headercustomone {
    font-weight: bold;
    text-align: center;
    margin-top: -60px;
    margin-bottom: 20px;
  }
  .headercustomtwo {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .imagecustomone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -80px;
  }
  .imagecustomtwo {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: -75px;
  }

  .cardLayoutAdditional {
    margin-left: -15px;
  }
  .cardBoxAdditional {
    height: 320px !important;
    width: 240px !important;
    margin-left: 10px;
    margin-top: 1px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
  .boxSubPara {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
   .webbanner{
    display: none;
  }
  .top-left {
    display: none;
  }
  .centered {
    display: none;
  }
  .servicesHeading {
    margin-top: 100px !important;
  }
  .briefContainerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .briefContainerTwo {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
   .briefingcustomone {
    display: flex;
    flex-direction: column;
    min-width: 335px !important;
    margin-left: 23px;
    margin-right: 20px;
    width: 50%;
  }
  .briefingcustomtwo {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-left: -51px;
    margin-right: 20px;
    width: 50%;
  }
  .headercustomone {
    font-weight: bold;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  .headercustomtwo {
    font-weight: bold;
    text-align: center;
  }
  .imagecustomone {
    display: block;
    object-fit: fill;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 354px;
    margin-top: -100px;
  }
  .imagecustomtwo {
    display: block;
    object-fit: fill;
    width: 360px;
    height: 354px;
    margin-left: auto;
    margin-right: auto;
    
  }
  .additionalServiceHeading {
    padding: 10px;
  }
  .cardLayoutContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 15px;
  }
  .cardLayoutAdditional {
    display: flex;
    flex-direction: column;
  }
  .imageLogo {
    margin-top: 40px !important;
  }
  .cardBoxAdditional {
    height: 320px;
    min-width: 370px;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .boxPara {
    font-size: 19px;
    margin-top: 2px;
  }
  .boxSubPara {
    font-size: 17px;
  }
}
