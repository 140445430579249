.sidebar-contact{
  position:fixed;
  top:60%;
  right:-350px;
  z-index: 100;
  transform:translateY(-50%);
  width:350px;
  height:auto;
  padding:40px;
  background:#fff;
  box-shadow: 0 20px 50px rgba(0,0,0,.5);
  box-sizing:border-box;
  transition:0.5s;
}
.sidebar-contact.active{
  right:0;
}

.sidebar-contact h2{
  margin:0 0 20px;
  padding:0;
}

.toggle{
  position:absolute;
  text-align:center;
  cursor:pointer;
  top:0;
  right:350px;
  line-height:48px;
}
.toggle:before{
  
  font-family:fontAwesome;
  font-size:18px;
  color:#fff;
}

@media(max-width:768px)
{
  .sidebar-contact{
    width:100%;
    height:100%;
    left:-100%;
  }
  .sidebar-contact .toggle{
    top:50%;
    transform:translateY(-50%);
    transition:0.5s;
  }
  .sidebar-contact.active .toggle
  {
    top:0;
    right:0;
    transform:translateY(0);
  }
  .scroll{
    width:100%;
    height:100%;
    overflow-y:auto;
  }
}