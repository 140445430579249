.submitBtn {
  width: 200px;
  margin-right: 130px;
}
.modalheading {
  margin-left: 100px;
}
.feedbackTxt{
  color: black;
}
.successgif{
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .submitBtn {
    margin-right: 140px;
  }
}
@media screen and (max-width: 480px) {
  .submitBtn {
    margin-right: 85px;
  }
  .modalheading {
    margin-left: 50px;
  }
}
