.clientlogodiv{
    background-color: white;
    height: 400px;
    
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2);
}

.clientlogo {
  width: 280px;
  height: 200px;
  object-fit: contain;
}
.carouseldiv {
  margin-bottom: 70px;
}

@media screen and (max-width: 1024px) {
  .clientlogo {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .clientlogo {
    width: 150px;
    height: 200px;
    margin-right: 5px;
    object-fit: contain;
  }
}

@media screen and (max-width: 480px) {
  .clientlogodiv{
    background-color: white;
    height: 600px;
    margin-top: 100px;
}
}