.testimonialMainContainer {
  background-color: black;
  height: 500px;
  width: 100%;
}

.reviewContainer {
  display: flex;
  margin-bottom: 50px !important;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
  margin-left: 10px;
}

.testimonialCard {
  margin-right: 10px;
  width: 550px;
  margin-top: 40px;
  height: 350px;
  padding: 20px;
}

.cardPara {
  margin-top: 10px;
  width: 450px;
  height: 200px;
}

@media screen and (max-width: 1024px) {
  .reviewContainer {
    display: flex;
    margin-bottom: 100px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .reviewContainer {
    display: flex;
    margin-bottom: 100px;
    margin-left: 10px;
  }
  .cardPara {
    margin-top: 10px;
    font-size: 15px;
    width: 300px;
  }
  .testimonialCard {
    margin-right: 10px;
    width: 550px;
    margin-top: 40px;
    height: 350px;
    padding: 20px;
  }
}

@media screen and (max-width: 480px) {
  .testimonialMainContainer {
    background-color: black;
    height: 900px;
    width: 100%;
  }
  .reviewContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    margin-left: 22px;
  }
  .cardPara {
    margin-top: 10px;
    font-size: 15px;
    width: 300px;
  }
  .testimonialCard {
    margin-right: 10px;
    width: 330px;
    margin-top: 40px;
    height: 350px;
    padding: 20px;
  }
}
